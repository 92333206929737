/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';
/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import './theme/mixins';

@import './theme/variables';

@import './theme/variables.deananddavid';

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.mobile {
	display: none !important;
	@include mobile {
		display: block !important;
	}
}

.desktop {
	display: none !important;
	@include desktop {
		display: block !important;
	}
}

ion-button,
.add-to-order-button {
	cursor: pointer;
	--background: var(--ion-color-primary);
	--background-activated: var(--ion-color-primary-shade);
	--background-hover: var(--ion-color-primary-shade);
	--riple-color: var(--ion-color-primary);
	--background-focused: var(--ion-color-primary-shade);
	color: var(--ion-color-primary-contrast);
	--color: var(--buttons-text-color);
	min-height: 54px;
	font-family: var(--buttons-font-family);
	text-align: center;
	--border-radius: var(--buttons-border-radius);
	--box-shadow: none;
	font-size: var(--buttons-font-size);
	margin: 0;
	text-transform: var(--buttons-text-transform);
	color: var(--buttons-text-color);
	&.button-small {
		font-size: small;
		min-height: auto;

		ion-icon {
			margin-right: 4px;
		}
	}

	&.invalid {
		--background: darkgrey;
		color: grey;
	}
	&.white {
		--background: white;
		color: var(--ion-color-primary);
		--background-activated: #e5ebea;
		--background-hover: #e5ebea;
		--background-focused: #e5ebea;
		--riple-color: white;
	}
	&.content-only {
		width: auto;
		height: auto;
		border: 0;
		margin: 0;
		padding: 0 5px;
		--padding-start: 0;
		--padding-end: 0;
		--box-shadow: none;
		--background: none;

		ion-icon {
			margin-right: 0;
		}
	}
}
.menu-modal {
	--box-shadow: none;
	--border-radius: 0px;
}
.menu-modal::part(content) {
	position: absolute;
	right: 0px;
	height: 100%;
}
.item-modal {
	@include desktop {
		--height: 90vh;
		--width: 550px;
	}
}
.allergens-modal {
	@include desktop {
		--height: 90vh;
		--width: 550px;
	}
	@include mobile {
		--height: 90vh;
		--width: 90vw;
	}
}
.error_container {
	height: 20px;
	position: relative;
	margin-bottom: -5px;
}

.error_container_highter {
	height: 20px;
}

.input-error {
	position: absolute;
	opacity: 0;
	color: rgb(119, 25, 25);

	font-size: 12px;
}

.input_info {
	position: absolute;
	opacity: 0;
	color: rgb(119, 25, 25);
	font-family: AppFontLight;
	font-size: 12px;
	letter-spacing: 0em;
}

.ion-invalid.ion-info {
	--highlight-background: #6b6b6b;
}

.visible {
	opacity: 1;
	transition: opacity 0.3s;
}
ion-input {
	font-family: AppFontBold, sans-serif;
	color: var(--ion-color-secondary);
	font-size: 16px;
}


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}


input[type=number] {
    -moz-appearance:textfield;
}

app-payment-modal {
	h1 {
	  font-family: AppFontBold, sans-serif;
	  margin-left: 20px !important;
	  font-weight: bold !important;
	  font-size: 16px !important;
	  line-height: 20px !important;
	  color: var(--ion-color-secondary) !important;

	}
	.payment-input {
	  font-family: AppFont, sans-serif;
	  --payment-modal-item-background: white !important;
	  border-radius: 7px !important;
	}
  
	.wpwl-group {
	  position: relative;
	}
  
	.wpwl-label {
	  position: absolute;
	  top: -7px;
	  left: 15px;
	  z-index: 10;
	  background-color: #fff;
	  color: #ccc;
	  width: fit-content;
	  padding: 0 5px;
	  font-size: 10px;
	}
  
	.wpwl-control-country {
	  margin-top: 0 !important;
	}
  
	iframe {
	  background: white;
	  background: white;
    	height: 50px;
		border: 0.5px solid #e5e5e5 !important;

    	margin-left: 10px;
    	border-radius: 16px;
    	padding: 5px;
		box-shadow: 0px 4px 10px rgba(33, 33, 33, 0.02);
		width: 100%;
	}
	ion-item {
	  font-family: AppFont, sans-serif;
  
	  box-sizing: border-box;
	  border: 0.5px solid #e5e5e5 !important;
	  box-shadow: 0px 4px 10px rgba(33, 33, 33, 0.02);
	  border-radius: 15px !important;
	  color: var(--ion-color-secondary) !important;
	  ion-icon {
		zoom: 2 !important;
	  }
	}
	.wpwl-button-pay {
	  float: none !important;
	  width: 100%;
	  background: var(--ion-color-primary);
	  border-radius: 28px;
	  background-color: var(--ion-color-primary);
	  border-color: var(--ion-color-primary);
	  font-family: AppFontBold, sans-serif !important;
	}
  }
  
.wpwl-group-button {
	display: flex;
	place-content: center;
	flex-direction: row;
  }
  
  .wpwl-wrapper {
	width: 100%;
  }
  
  .wpwl-control {
	border: 1px solid #e5e5e5 !important;
	border-radius: 7px;
	padding: 4px 8px;
  }
  
  .wpwl-label {
	width: 100%;
	font-size: 13px;
	padding: 4px 10px;
  }
  
  .wpwl-wrapper-brand {
	width: 80%;
  }
  
  .wpwl-brand-card {
	width: 20%;
  }
  
  .wpwl-sup-wrapper-state {
	display: none;
  }
  
  .wpwl-sup-wrapper-street2 {
	display: none;
  }
  
  .cc_dialog {
	width: 100% !important;
	max-width: 100% !important;
	left: 0;
	right: 0;
	padding: 16px !important;
	background: var(--ion-color-primary) !important;
  
	p {
	  font-size: 10px !important;
	}
  
	h1 {
	  font-size: 16px;
	}
  }
  
  .cc_cp_f_powered_by {
	display: none !important;
	content: none !important;
  }
  
  .cc_cp_m_content_entry {
	height: auto !important;
	max-height: 500px !important;
	overflow: auto !important;
  }
  
  .checkbox_cookie_consent {
	display: none;
  }
  
  .cc_cp_f_save {
	button {
	  background: var(--ion-color-primary) !important;
	  color: white !important;
	}
  }
  .button_wrapper {
	display: flex !important;
	flex-wrap: wrap !important;
	justify-content: center !important;
	@include desktop {
	  justify-content: flex-start !important;
	}
  }
  .cc_b_cp {
	color: white !important;
	background: var(--ion-color-primary) !important;
	border: 2px solid white !important;
	border-radius: 28px !important;
  }
  
  .cc_b_ok {
	color: var(--ion-color-primary) !important;
	border: 2px solid var(--ion-color-primary) !important;
	background-color: white !important;
	border-radius: 28px !important;
  }
  .cc_b_df {
	margin-left: 5px !important;
	color: white !important;
	border: 2px solid white !important;
	background-color: var(--ion-color-primary) !important;
	border-radius: 28px !important;
	@include mobile {
	  margin-top: 10px !important;
	}
  }
  .cdk-overlay-container {
	position: absolute;
	bottom: 10px;
	width: 80%;
	left: 50%;
	transform: translateX(-50%);

	@include mobile {
		left: 0 !important;
		transform: none !important;
		width: 70%;
	}
	.mdc-snackbar__surface {
		background: var(--ion-color-secondary) !important;
		color: white;
		border-radius: 16px;
		overflow: hidden;
		--mdc-snackbar-container-shape: 16px;
		text-align: center;
	}
}

.mat-mdc-snack-bar-container {
	@include mobile {
		margin: 0 !important;
	}
}

.eight_height {
	--height: 90vh;
	--width: 90vw;
	--backdrop-opacity: 1 !important;
	--border-radius: 16px !important;
	@include desktop {
		--width: 460px;
		--height: 80vh;
	}
}
.wrapper_for_types {
	.item {
		cursor: pointer !important;
	}
}
ion-modal.auto-height {
	background: rgba(0, 0, 0, 0.2);
	--height: auto;
	--width: 80vw;
	--border-radius: 16px;
	@include desktop {
		--width: 400px;

	}

	.modal-wrapper {
	  min-height: unset !important;

		overflow: hidden;
	  .ion-page {
		position: relative;
		contain: content;
		max-height: 95vh;
  
		.modal-content {
		  overflow: auto;
		}
	  }
	}
  }
  ion-modal.recommendation-dialog {
	background: rgba(0, 0, 0, 0.2);
	--height: 90vh;
	--width: 90vw;
	--border-radius: 16px;
	@include desktop {
		--width: 550px;

	}
	.modal-wrapper {
	  min-height: unset !important;

		overflow: hidden;
	  .ion-page {
		position: relative;
		contain: content;
		max-height: 95vh;
  
		.modal-content {
		  overflow: auto;
		}
	  }
	}
  }
  .cluster {
	display: flex;
	justify-content: center;
  
	> img {
	  position: absolute;
	  top: 0;
	  bottom: 0;
	  right: 0;
	  left: 0;
	  margin: 0 auto;
	  content: url(/assets/pin_multi.svg);
	}
  
	> div {
	  display: contents;
	  line-height: 2rem !important;
  
	  > span {
		z-index: 2;
		color: white;
		font-size: 17px;
	  }
	}
  }
  